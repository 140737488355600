import React, { useRef } from 'react';
import './App.css';

function App() {
  const firstScreenRef = useRef(null);
  const secondScreenRef = useRef(null);

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className="App">
      {/* 首屏 */}
      <section ref={firstScreenRef} className="first-screen">
        <header className="navbar">
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="安心聊 Logo" />
          </div>
          <div className="nav-links">
            <a href="#home" onClick={() => scrollToSection(firstScreenRef)}>首页</a>
            <a href="#contact" onClick={() => scrollToSection(secondScreenRef)}>联系我们</a>
          </div>
        </header>
        <div className="first-screen-content">
          <h1>安心聊</h1>
          <p>随时随地同我们的智能客服沟通</p>
        </div>
      </section>

      {/* 第二屏 */}

      <section ref={secondScreenRef} className="second-screen">
        <div className="second-screen">
          {/* 内容区 */}
          <div className="content-area">
            {/* 信息区 */}
            <div className="info-section">
              <div className="title">联系我们</div>
              <div className="details-section">
                <div>
                  <span className="detail-item">地址</span>
                  <p className="detail-info">上海市金山区朱泾商园街54号</p>
                </div>
                <div>
                  <span className="detail-item">邮箱</span>
                  <p className="detail-info">anxinliao@qq.com</p>
                </div>
                <div>
                  <span className="detail-item">QQ号</span>
                  <p className="detail-info">3888271858</p>
                </div>
              </div>
            </div>
            
            {/* 图片区 */}
            <div className="image-section">
              <img src={`${process.env.PUBLIC_URL}/contact-image2.png`} alt="客服图片1" />
              <img src={`${process.env.PUBLIC_URL}/contact-image1.png`} alt="客服图片2" />
            </div>
          </div>

          {/* 脚标区 */}
          <div className="footer">
            <p>网站ICP备案号：沪ICP备12345678号-1</p>
          </div>
        </div>

      </section>
    </div>
  );
}

export default App;
